import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import ArticleCarousel from "../components/ArticleCarousel"
import {
  SectionPage,
  Container,
  SectionPageTitle,
  SectionDescription,
  SectionTitle,
  BreadCrumb,
  ExploreMore,
} from "../components/Section"
import MapSection from "../sections/About/MapSection"
import AddressIcon from "../components/Icons/AddressIcon"
import EmailIcon from "../components/Icons/EmailIcon"
import CallPhone from "../components/Icons/CallPhone"
// import PrimaryButton from "../components/Button/PrimaryButton"
// import Check from "../images/Check.png"

const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 200px;
  @media (max-width: 768px) {
    height: 750px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
  }
  @media (max-width: 600px) {
    height: 600px;
    grid-row-gap: 10px;
  }
  @media (max-width: 440px) {
    height: 500px;
    grid-row-gap: 0px;
  }
`
const GridItem = styled.div`
  padding: 10%;
  border: 1px solid #98dfff;
  & :hover {
    box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
    border-radius: 5px;
    padding: 15% 10%;
    cursor: pointer;
  }
`
const Icon = styled.div`
  width: 20%;
  display: inline-block;
  vertical-align: middle;
  & svg {
    width: 85%;
  }
  @media (max-width: 1100px) {
    width: 15%;
  }
`
const TextWrap = styled.div`
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  & h4 {
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-bottom: 5px;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 1100px) {
    width: 80%;
    margin-left: 5%;
  }
`

// const ListCity = styled.ul`
//   list-style: none;
//   margin: 0 0 30px 20px;
//   padding: 0;
//   column-count: 2;
//   column-fill: balance;
//   justify-content: center;
//   @media (min-width: 768px) {
//     column-count: 3;
//   }
//   @media (min-width: 992px) {
//     column-count: 4;
//   }
//   @media (min-width: 1200px) {
//     column-count: 5;
//   }
// `
// const ListItemCity = styled.li`
//   vertical-align: text-top;
//   font-size: 16px;
//   line-height: 26px;
//   font-weight: 600;
//   color: #1e4156;
//   margin: 0 0 10px;
//   padding: 0;
//   &:before {
//     content: url(${Check});
//     display: inline-block;
//     margin-left: -20px;
//     width: 20px;
//     vertical-align: top;
//   }
//   a {
//     color: #000;
//     font-weight: 700;
//     line-height: 26px;
//     display: inline-block;
//     &:hover {
//       color: #fc0002;
//     }
//   }
// `

const ServiceLocationPage = pageData => {
  const data = pageData.data.contentfulServiceLocation
  const AticleSectionData =
    pageData.data.contentfulServiceLocation.articleSection
  const ArticleData = pageData.data.allContentfulArticle
  return (
    <Layout location={pageData.location}>
      <SEO title={data.metaTitle} description={data.metaDescription} />
      <SectionPage
        ept="160px"
        epb="0"
        xpt="140px"
        xpb="0"
        pt="100px"
        pb="0"
        bg="#fff"
      >
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home</Link> / <span>Service Location </span>
          </BreadCrumb>
          <SectionPageTitle textAlign="center">
            {data.heroTitle}
          </SectionPageTitle>
          <SectionDescription
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: data.heroDescription.childMarkdownRemark.html,
            }}
          />
        </Container>
        <MapSection
          stateName={data.mapSection.stateName}
          mapImage={data.mapSection.mapImage}
          cities={data.mapSection.cities}
        />
      </SectionPage>

      <SectionPage
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
      >
        <Container>
          <SectionTitle>{data.ourOffice.title}</SectionTitle>
          <SectionDescription
            maxWidth="720px"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: data.ourOffice.description.childMarkdownRemark.html,
            }}
          />
          {data.ourOffice.features.map(content => {
            return (
              <div>
                {/* <SectionTitle>{content.title}</SectionTitle> */}
                <AddressGrid>
                  <GridItem>
                    <Icon>
                      <AddressIcon />
                    </Icon>
                    <TextWrap>
                      <h4>Address</h4>
                      <p>{content.address}</p>
                    </TextWrap>
                  </GridItem>
                  <GridItem>
                    <a href={`mailto:${content.email}`}>
                      <Icon>
                        <EmailIcon />
                      </Icon>
                      <TextWrap>
                        <h4>Email Address</h4>
                        <p>{content.email}</p>
                      </TextWrap>
                    </a>
                  </GridItem>
                  <GridItem>
                    <a href={`tel:${content.phone}`}>
                      <Icon>
                        <CallPhone />
                      </Icon>
                      <TextWrap>
                        <h4>Contact Number</h4>
                        <p>{content.phone}</p>
                      </TextWrap>
                    </a>
                  </GridItem>
                </AddressGrid>
              </div>
            )
          })}
        </Container>
      </SectionPage>
      {/* <SectionPage
				ept="80px"
				epb="80px"
				xpt="40px"
				xpb="40px"
				pt="30px"
				pb="30px"
				bgBefore="#eee"
				bgBeforeWidth="100%"
				bgBeforeHeight="1px"
				topBefore="0px"
			>
				<Container>
					<SectionTitle>Areas We Serve In <strong>Floria</strong></SectionTitle>
					<SectionDescription textAlign="center"><p>We cover all locations throughout the state of <strong>Floria</strong>, including:</p></SectionDescription>
					<ListCity>
						{['Jacksonville','Tallahassee','Pensacola','Fort Lauderdale','St. Petersburg','Orlando','Port St. Lucie','Tampa','Hialeah'].map((item, index) =>
							<ListItemCity key={index}>{item}</ListItemCity>
						)}
					</ListCity>
					<ExploreMore><button type='button' className="btn-lg"><PrimaryButton text="Load More" /></button></ExploreMore>
				</Container>
			</SectionPage> */}
      <ArticleCarousel sectionData={AticleSectionData} data={ArticleData} />
      <CallToAction />
    </Layout>
  )
}

export default ServiceLocationPage

export const pageQuery = graphql`
  query ServiceLocationPageQuery {
    contentfulServiceLocation {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      ourOffice {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          address
          email
          phone
        }
      }
      articleSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      mapSection {
        stateName
        mapImage {
          fluid(maxWidth: 200, quality: 100) {
            base64
            sizes
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        cities {
          cityName
          url
        }
      }
    }
    allContentfulArticle {
      edges {
        node {
          title
          publishDate(formatString: "DD, MMMM YYYY")
          description {
            description
          }
          tumbnail {
            fluid(maxHeight: 700) {
              base64
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              aspectRatio
            }
          }
          category {
            name
          }
          createdAt(fromNow: true)
        }
      }
    }
  }
`
