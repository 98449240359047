import React from 'react'

const LeftArrowIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="32.4" height="12.343" viewBox="0 0 32.4 12.343">
	  <path d="M5.584.243A.854.854,0,0,1,6.175,0a.828.828,0,0,1,.59.243L12.1,5.453a.8.8,0,0,1,0,1.16.85.85,0,0,1-1.187,0L7.013,2.8V19.43a.839.839,0,0,1-1.678,0V2.8l-3.9,3.813a.85.85,0,0,1-1.187,0,.8.8,0,0,1,0-1.16Z" transform="translate(12.15 12.343) rotate(-90)" fill={props.fill}/>
	  <path d="M5.584.243A.854.854,0,0,1,6.175,0a.828.828,0,0,1,.59.243L12.1,5.453a.8.8,0,0,1,0,1.16.85.85,0,0,1-1.187,0L7.013,2.8a1.4,1.4,0,0,0-.839-.385,1.342,1.342,0,0,0-.839.385l-3.9,3.813a.85.85,0,0,1-1.187,0,.8.8,0,0,1,0-1.16Z" transform="translate(6.075 12.343) rotate(-90)" fill={props.fill} opacity="0.4"/>
	  <path d="M5.584.243A.854.854,0,0,1,6.175,0a.828.828,0,0,1,.59.243L12.1,5.453a.8.8,0,0,1,0,1.16.85.85,0,0,1-1.187,0L7.013,2.8a1.4,1.4,0,0,0-.839-.385,1.342,1.342,0,0,0-.839.385l-3.9,3.813a.85.85,0,0,1-1.187,0,.8.8,0,0,1,0-1.16Z" transform="translate(0 12.343) rotate(-90)" fill={props.fill} opacity="0.2"/>
	</svg>
)

export default LeftArrowIcon