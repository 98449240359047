import React from "react"
import Map from "../../components/Map"
import { SectionPage } from "../../components/Section"

const MapSection = ({ stateName, mapImage, cities }) => {
  return (
    <SectionPage
      overflow="hidden"
      ept="80px"
      xpt="60px"
      pt="40px"
      pb="0"
      bg="#1E4156"
    >
      <Map stateName={stateName} mapImage={mapImage} cities={cities} />
    </SectionPage>
  )
}

export default MapSection
